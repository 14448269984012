import React, { useState } from "react"
import { Link } from "gatsby"
import { Modal } from "react-bootstrap"
import VideoModal from "../shared/video-modal"
import * as style from "./intern-student.module.css"

const InternStudent = ({ pagedata }) => {
  const [showWdaVideo, setShowWdaVideo] = useState(false)

  const handleCloseWdaVideo = () => setShowWdaVideo(false)
  const handleShowWdaVideo = () => setShowWdaVideo(true)

  const callbackWdaModal = () => {
    setShowWdaVideo(false)
  }

  const videoTitle = "Anna Taylor - Work Experience Summary"

  return (
    <>
      <div className="container mb-6">
        <div className="row">
          <div className="col-12 col-lg-4 mb-3 mb-lg-0">
            <h3 className="lower">{pagedata.internLhsTitle}</h3>
          </div>
          <div className="col-12 col-lg-8 row m-0 p-0">
            <div className="col-md-6">
              <p
                dangerouslySetInnerHTML={{ __html: pagedata.internIntroPara }}
              />
              <Link
                to="/contact/"
                className="ani_grey_arrow mt-3 d-inline-block"
                title="Contact Us"
              >
                Get in touch to find out more
              </Link>
            </div>
            <div className="col-md-6">
              <img
                className={`${style.modal_launch} w-100 mt-4 mt-md-0`}
                src="https://res.cloudinary.com/dvmdv4ttu/image/upload/f_auto/q_70/img/Anna-Taylor_rwaaqt.webp"
                alt={videoTitle}
                role="link"
                onClick={handleShowWdaVideo}
              />
              <h5 className="mt-3 fw-bold">{videoTitle}</h5>
            </div>
          </div>
        </div>
      </div>

      <Modal
        show={showWdaVideo}
        onHide={handleCloseWdaVideo}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
      >
        <VideoModal close={callbackWdaModal} videolabel={videoTitle} />
      </Modal>
    </>
  )
}

export default InternStudent
