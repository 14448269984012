import React from "react"
import { AdvancedImage } from "@cloudinary/react"
import { Cloudinary } from "@cloudinary/url-gen"
import { fill } from "@cloudinary/url-gen/actions/resize"
import * as style from "./wda-benefits.module.css"

const WdaBenefits = ({ pagedata }) => {
  const cld = new Cloudinary({
    cloud: {
      cloudName: process.env.GATSBY_CLOUDINARY_CLOUD,
    },
    url: {
      analytics: false,
    },
  })

  return (
    <div className="container mb-6">
      <div className="row">
        <div className="col-12 col-lg-4 mb-3 mb-lg-0">
          <h3 className="lower">{pagedata.wdaBenefitsTitle}</h3>
        </div>
        <div className="col-12 col-lg-8">
          <div
            dangerouslySetInnerHTML={{ __html: pagedata.wdaBenefitsIntro }}
          />
          <div className="row pt-3 pt-sm-0">
            <ul className=" col-12 col-sm-6 list-unstyled mb-0 mt-0 mt-sm-3 mt-lg-5">
              {pagedata.wdaLhsBenefits.map((lhsBen, index) => {
                let slug = lhsBen.wdaBenefitIcon.mediaDetails.file
                let altText = lhsBen.wdaBenefitIcon.altText

                let theImage = cld.image(
                  `${process.env.GATSBY_API_FOLDER_URL}${slug}`
                )

                theImage.resize(fill().width(100).height(100))
                theImage.format("auto")

                return (
                  <li key={index} className=" mb-3 d-flex border-bottom pb-3">
                    <span className={style.icon_clearance}>
                      <AdvancedImage
                        cldImg={theImage}
                        alt={`${altText} icon`}
                        className="inline-icon"
                      />
                    </span>
                    {lhsBen.wdaBenefitTitle}
                  </li>
                )
              })}
            </ul>
            <ul className=" col-12 col-sm-6 list-unstyled mb-0 mt-0 mt-sm-3 mt-lg-5">
              {pagedata.wdaRhsBenefits.map((rhsBen, index) => {
                let slug = rhsBen.wdaBenefitIcon.mediaDetails.file
                let altText = rhsBen.wdaBenefitIcon.altText

                let theImage = cld.image(
                  `${process.env.GATSBY_API_FOLDER_URL}${slug}`
                )

                theImage.resize(fill().width(100).height(100))
                theImage.format("auto")

                return (
                  <li key={index} className=" mb-3 d-flex border-bottom pb-3">
                    <span className={style.icon_clearance}>
                      <AdvancedImage
                        cldImg={theImage}
                        alt={`${altText} icon`}
                        className="inline-icon"
                      />
                    </span>
                    {rhsBen.wdaBenefitTitle}
                  </li>
                )
              })}
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}

export default WdaBenefits
