import React from "react"
import { graphql } from "gatsby"
import Layout from "./Layout"
import WDAHero from "../components/wda/hero"
import JobDescription from "../components/wda/job-description"
import WdaBenefits from "../components/wda/wda-benefits"
import WhatStudentsSay from "../components/wda/what-students-say"
import InternStudent from "../components/wda/intern-student"
import { seoHead } from "../components/utils.js"

export const query = graphql`
  query ($id: ID!) {
    wpgraphql {
      page(id: $id) {
        id
        title
        content
        uri
        cfWDA {
          wwdHeroTitle
          wwdHeroSubtitle
          wdaHeroImg1 {
            altText
            mediaDetails {
              file
            }
          }
          wdaHeroImg2 {
            altText
            mediaDetails {
              file
            }
          }
          wdaHeroImg3 {
            altText
            mediaDetails {
              file
            }
          }
          wdaWhyWdaTitle
          whyWdaMainContent
          wdaBenefitsTitle
          wdaBenefitsIntro
          wssIntroPara
          wssLhsTitle
          wssReviews {
            wssTheComment
            wssTheDate
          }
          wdaLhsBenefits {
            wdaBenefitTitle
            wdaBenefitIcon {
              altText
              mediaDetails {
                file
              }
            }
          }
          wdaRhsBenefits {
            wdaBenefitTitle
            wdaBenefitIcon {
              altText
              mediaDetails {
                file
              }
            }
          }
          internLhsTitle
          internIntroPara
        }
        seo {
          title
          metaDesc
          opengraphTitle
          opengraphDescription
          opengraphUrl
          metaRobotsNoindex
          metaRobotsNofollow
          opengraphImage {
            mimeType
            mediaDetails {
              file
            }
          }
        }
      }
    }
  }
`
const PageTemplateDigitalAcademyParent = ({ data }) => {
  const page = data.wpgraphql.page

  return (
    <Layout seo={page.seo} uri={page.uri}>
      <WDAHero pagedata={page.cfWDA} />

      <JobDescription pagedata={page.cfWDA} />

      <WdaBenefits pagedata={page.cfWDA} />

      <WhatStudentsSay pagedata={page.cfWDA} />

      <InternStudent pagedata={page.cfWDA} />
    </Layout>
  )
}

export default PageTemplateDigitalAcademyParent

// Metadata in head from gatsby ^v4.19.0
// replaces helmet which wasn't generating the tags statically

export const Head = ({ data }) => {
  const page = data.wpgraphql.page
  const seoTags = seoHead(page.seo, page.uri)
  return seoTags
}
