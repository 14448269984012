import React from "react"

const WhatStudentsSay = ({ pagedata }) => {
  return (
    <div className="container mb-6">
      <div className="row">
        <div className="col-12 col-lg-4 mb-3 mb-lg-0">
          <h3 className="lower">{pagedata.wssLhsTitle}</h3>
        </div>
        <div className="col-12 col-lg-8">
          <p
            className="mb-5"
            dangerouslySetInnerHTML={{ __html: pagedata.wssIntroPara }}
          />
          {pagedata.wssReviews.map((review, index) => {
            return (
              <div
                key={index}
                className="border-top pt-4 pb-4 d-flex flex-wrap align-items-start"
              >
                <div className="col-12 col-md-10 pe-5">
                  <h3 className="h4">{review.wssTheComment}</h3>
                </div>
                <div className="col-12 col-md-2 text-lg-end">
                  <p className="mb-0">{review.wssTheDate}</p>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default WhatStudentsSay
