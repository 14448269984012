import React from "react"

const JobDescription = ({ pagedata }) => {
  return (
    <div className="container mb-6">
      <div className="row">
        <div className="col-12 col-lg-4 mb-3 mb-lg-0">
          <h3 className="lower">{pagedata.wdaWhyWdaTitle}</h3>
        </div>
        <div
          className="col-12 col-lg-8"
          dangerouslySetInnerHTML={{ __html: pagedata.whyWdaMainContent }}
        />
      </div>
    </div>
  )
}

export default JobDescription
