import React from "react"
import { Modal } from "react-bootstrap"

const VideoModal = props => {
  return (
    <>
      <Modal.Header
        closeButton
        style={{
          zIndex: "9",
          border: "0",
          position: "absolute",
          background: "white",
          width: "100%",
          top: "0",
          left: "0",
        }}
      >
        <Modal.Title
          style={{
            color: "#212529",
            fontSize: "16px",
            letterSpacing: "initial",
            fontFamily: "MaisonNeue-Thin, Helvetica, sans-serif",
            fontWeight: "lighter",
          }}
        >
          {props.videolabel}
        </Modal.Title>
      </Modal.Header>
      <video playsInline controls autoPlay loop style={{ objectFit: "cover" }}>
        <source
          src="https://res.cloudinary.com/dvmdv4ttu/video/upload/video/anna-wda_c1js2c.mp4"
          type="video/mp4"
        />
      </video>
    </>
  )
}

export default VideoModal
